import { space } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Link } from '../link';

const NavLink = ({ href, children, ...rest }) => (
  <Link href={href} {...rest}>
    {children}
  </Link>
);

const StyledNavLink = styled(NavLink)`
  ${space}
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;
`;

NavLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
};

NavLink.defaultProps = {
  href: null,
};

export { StyledNavLink as NavLink };
