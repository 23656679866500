import { isNil } from 'lodash';
import { space, variant as styledVariant } from 'styled-system';
import styled from 'styled-components';

const dividerVariants = styledVariant({
  variants: {
    lightBackground: {
      backgroundColor: 'background',
    },
    darkBackground: {
      backgroundColor: 'backgroundDark',
    },
    lightGrey: {
      backgroundColor: 'grey50',
    },
    darkGrey: {
      backgroundColor: 'grey100',
    },
  },
});

const Divider = styled.div.attrs(({ variant }) => {
  const dividerVariant = isNil(variant) ? 'darkBackground' : variant;

  return { variant: dividerVariant };
})`
  ${space}
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '1px' }) => height};
  ${dividerVariants}
`;

export { Divider };
