import { useContext } from 'react';

import { MarketContext } from '../../components/market-provider';

const useMarket = () => {
  const currentMarket = useContext(MarketContext);

  if (currentMarket === undefined) {
    throw new Error('useMarket must be nested within a MarketProvider');
  }

  return currentMarket;
};

export { useMarket };
