import PropTypes from 'prop-types';

const Logo = ({ height, width }) => (
  <img src="/static/images/brand/logo.svg" alt="Nando’s logo" height={height} width={width} />
);

Logo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

Logo.defaultProps = {
  height: undefined,
  width: 160,
};

export { Logo };
