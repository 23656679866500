import React from 'react';
import styled from 'styled-components';

import { colors } from '../../constants';
import { DesktopHeader } from './desktop-header';
import { MobileHeader } from './mobile-header';
import { profileDefaultProps, profilePropTypes } from '../../prop-types';

const Header = ({ profile, handleSignOut }) => (
  <Wrapper>
    <DesktopHeader display={{ xs: 'none', xl: 'flex' }} profile={profile} handleSignOut={handleSignOut} />
    <MobileHeader display={{ xs: 'flex', xl: 'none' }} profile={profile} handleSignOut={handleSignOut} />
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${colors.grey100};
`;

Header.propTypes = profilePropTypes;
Header.defaultProps = profileDefaultProps;

export { Header };
