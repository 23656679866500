import { startsWith } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../../analytics';
import { Flex } from '../../grid';
import { Label } from '../../typography';
import { NavLink } from '../../nav-link';
import { useNavigationItems } from '../../../hooks/use-navigation-items';

const DesktopNavigation = () => {
  const router = useRouter();
  const navItems = useNavigationItems('primary');

  const checkActiveState = href => {
    const querySlug = `/${router?.query?.slug}`;

    return startsWith(querySlug, href) || startsWith(router?.pathname, href);
  };

  return (
    <Flex width="100%" height="100%">
      {navItems.map(({ label, href, preserveLabel }) => (
        <NavLink
          onClick={() =>
            analytics.track(ANALYTICS_EVENTS.HEADER_NAV_ITEM_CLICKED, {
              text: label,
            })
          }
          href={href}
          label={label}
          key={href}
        >
          <NavLabel active={checkActiveState(href)} keepCase={!!preserveLabel}>
            {preserveLabel || label}
          </NavLabel>
        </NavLink>
      ))}
    </Flex>
  );
};

const NavLabel = styled(Label).attrs(() => ({ as: 'span' }))`
  align-items: center;
  border-bottom: 2px solid ${({ active, theme }) => (active ? theme.colors.neonTeal400 : 'transparent')};
  display: flex;
  height: 100%;
  margin: 0 0.5rem;
  transition: border-color 250ms ease-in-out;
  ${({ keepCase }) => keepCase && ` text-transform: none;`}

  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.colors.neonTeal400};
  }
`;

export { DesktopNavigation };
