import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';

import { AlertModal } from '../alert-modal';

const PageAlert = () => {
  const { AlertsStore } = useRootStore();
  const alert = AlertsStore.current;

  if (!alert) {
    return null;
  }

  return (
    <AlertModal buttonText={alert.dismissText} handleClose={AlertsStore.dismissCurrent} open title={alert.title}>
      {alert.body}
    </AlertModal>
  );
};

const ObservedPageAlert = observer(PageAlert);
export { ObservedPageAlert as PageAlert };
