import React from 'react';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';
import { Box, Container, Flex } from '../grid';
import { Divider } from '../divider';
import { Label } from '../typography';
import { Logo } from '../logo';
import { NavLink } from '../nav-link';
import { SecondaryNavigation } from './secondary-navigation';
import { useNavigationItems } from '../../hooks/use-navigation-items';

const Footer = () => {
  const navigationItems = useNavigationItems('secondary');

  return (
    <Box mt={1}>
      <Box py={{ xs: 4, xl: 2 }} backgroundColor="white">
        <Container>
          <Flex flexDirection="column" alignItems="center">
            <Box mb={2}>
              <Logo />
            </Box>

            <Flex justifyContent="center" flexWrap="wrap" width="100%" height="100%">
              {navigationItems.map(({ label, href, target }) => (
                <NavLink
                  p={1}
                  onClick={() => {
                    analytics.track(ANALYTICS_EVENTS.FOOTER_NAV_ITEM_CLICKED, {
                      text: label,
                    });
                  }}
                  href={href}
                  key={href}
                  target={target}
                >
                  <Label>{label}</Label>
                </NavLink>
              ))}
            </Flex>

            <Divider variant="lightGrey" my={1} />

            <SecondaryNavigation />
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export { Footer };
