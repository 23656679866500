import PropTypes from 'prop-types';

const buttonPropTypes = PropTypes.shape({
  text: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  onClick: PropTypes.func,
});

export { buttonPropTypes };
