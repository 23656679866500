import PropTypes from 'prop-types';

import { cmsImagePropTypes, cmsImagesPropTypes } from './cms-image';

const cmsProductPropTypes = PropTypes.shape({
  category: PropTypes.string,
  image: cmsImagesPropTypes,
  title: PropTypes.string,
});

const cmsProductsPropTypes = PropTypes.arrayOf(cmsProductPropTypes);

const productCardPropTypes = PropTypes.shape({
  category: PropTypes.string,
  href: PropTypes.string,
  image: cmsImagePropTypes,
  title: PropTypes.string,
});

export { cmsProductsPropTypes, productCardPropTypes, cmsProductPropTypes };
