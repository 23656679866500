import { productPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';

const orderItemPropTypes = PropTypes.shape({
  choices: PropTypes.instanceOf(Map),
  id: PropTypes.string.isRequired,
  notes: PropTypes.string,
  product: productPropTypes,
  quantity: PropTypes.number.isRequired,
  isReorderItem: PropTypes.bool,
});

export { orderItemPropTypes };
