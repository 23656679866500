import MaterialUIDrawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';

const Drawer = ({ children, ...props }) => <MaterialUIDrawer {...props}>{children}</MaterialUIDrawer>;

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Drawer };
