import { get } from 'lodash';

import { isCompetitionActive } from '../../util/is-competition-active';
import useRootStore from '../use-root-store';

const useCompetition = () => {
  const { CompetitionStore } = useRootStore();
  const competition = get(CompetitionStore, 'competition', null);
  const { entries = 0 } = competition || {};
  const isActive = isCompetitionActive(competition);
  const userCanEnter = Boolean(isActive && entries);

  return {
    ...competition,
    isActive,
    isLoading: CompetitionStore.loading,
    userCanEnter,
  };
};

export default useCompetition;
