import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

export const PageContext = createContext({});

const PageProvider = ({ children, title }) => {
  const contextValue = useMemo(() => ({ title }), [title]);

  return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>;
};

PageProvider.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export { PageProvider };
