import PropTypes from 'prop-types';

const deliveryAddressPropTypes = PropTypes.shape({
  street: PropTypes.string.isRequired,
  suburb: PropTypes.string.isRequired,
  unit: PropTypes.string,
  postcode: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
});

export { deliveryAddressPropTypes };
