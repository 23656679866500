import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';
import { Nav, NavButton } from './styles';
import { NavItem } from './nav-item';
import { P } from '../typography';
import { useAccountNavigationItems } from '../../hooks/use-account-navigation-items';

const AccountNav = ({ handleSignOut, routes, ...rest }) => {
  const router = useRouter();
  const defaultRoutes = useAccountNavigationItems();
  const displayRoutes = routes || defaultRoutes;

  return (
    <Nav {...rest}>
      {displayRoutes.map(({ label, href, target, badge }) => (
        <NavItem
          active={router ? href === router.pathname : false}
          onClick={() =>
            analytics.track(ANALYTICS_EVENTS.ACCOUNT_NAV_CLICKED, {
              text: label,
            })
          }
          href={href}
          key={label}
          label={label}
          target={target}
          badge={badge}
          px={{ xs: '1.5rem', md: 1 }}
          py={1}
        />
      ))}

      {handleSignOut && (
        <NavButton
          onClick={() => {
            analytics.track(ANALYTICS_EVENTS.ACCOUNT_NAV_CLICKED, {
              text: 'Sign Out',
            });

            handleSignOut();
          }}
          py={1}
          pl={{ xs: '1.5rem', md: 1 }}
        >
          <P variant={3}>Sign Out</P>
        </NavButton>
      )}
    </Nav>
  );
};

AccountNav.propTypes = {
  handleSignOut: PropTypes.func,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      badge: PropTypes.number,
    })
  ),
};

AccountNav.defaultProps = {
  handleSignOut: null,
  routes: undefined,
};

export { AccountNav };
