import PropTypes from 'prop-types';

const profilePropTypes = {
  profile: PropTypes.shape({
    givenName: PropTypes.string.isRequired,
    balances: PropTypes.shape({
      points: PropTypes.number.isRequired,
    }).isRequired,
  }),
  handleSignOut: PropTypes.func.isRequired,
};

const profileDefaultProps = {
  profile: null,
};

export { profilePropTypes, profileDefaultProps };
