import styled from 'styled-components';

import { breakpoints } from '../../../constants';
import { Flex } from '../../grid';

const ContentWrapper = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 2rem 1rem 0rem;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    padding: 1rem 1rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 0;
  }
`;

export { ContentWrapper };
