import { pricesPropTypes } from '@prop-types/prices';
import PropTypes from 'prop-types';

export const optionPropTypes = PropTypes.shape({
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  plu: PropTypes.string,
  points: 0,
  prices: pricesPropTypes,
  sortOrder: PropTypes.number,
  groupName: PropTypes.string,
  size: PropTypes.string,
  kilojoules: PropTypes.number,
  suitability: PropTypes.any,
  isAvailable: PropTypes.bool,
  image: PropTypes.string,
  isEligibleForDeliveryDiscount: PropTypes.bool,
  hidesBastingIfSelected: PropTypes.bool,
});
