import { observer } from 'mobx-react';
import { useCompetition, useRootStore } from '@nandosaus/state-management';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import styled from 'styled-components';

import { AccountNavigation } from '../account-nav';
import { Box } from '../../grid';
import { fontSize, navigation } from '../../../constants';
import { Icon } from '../../icons';
import { Label } from '../../typography';
import { NavLink } from '../../nav-link';
import { profileDefaultProps, profilePropTypes } from '../../../prop-types';
import { setLandingPage } from '../../../utils/auth';

const Profile = ({ profile, handleSignOut }) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const isSignedIn = !!profile;
  const { CompetitionStore } = useRootStore();
  const { isActive: isCompetitionActive } = useCompetition();

  if (isSignedIn) {
    return (
      <Box position="relative" data-testid="header-profile">
        <Button onClick={() => setIsOpen(!isOpen)} title="Open profile menu">
          <Box position="relative" mr="0.5rem">
            <Icon name="user" />
            {CompetitionStore.competition?.entries > 0 && isCompetitionActive && (
              <Box
                position="absolute"
                top="-6px"
                right="-6px"
                backgroundColor="periRed"
                borderRadius="50%"
                width="16px"
                height="16px"
                fontSize={fontSize[14]}
                lineHeight="16px"
                textAlign="center"
                color="white"
              >
                {CompetitionStore.competition.entries}
              </Box>
            )}
          </Box>
          <Box display={{ xs: 'none', sm: 'block', lg: 'block' }} textAlign="left">
            <Label>{profile.givenName}</Label>
            <Label color="perksRed" variant={2}>
              {profile.balances.points} points
            </Label>
          </Box>
        </Button>
        <AccountNavigation
          profile={profile}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          handleSignOut={handleSignOut}
        />
      </Box>
    );
  }

  return (
    <NavLink
      ml=".5rem"
      href={navigation.SIGN_IN}
      onClick={() => {
        if (router.asPath === navigation.PERI_PERKS_REGISTRATION) {
          setLandingPage(navigation.HOME);
        } else {
          setLandingPage(router.asPath);
        }
      }}
    >
      <Label color="greyPrimary">Sign In</Label>
    </NavLink>
  );
};

const Button = styled.button`
  align-items: center;
  display: flex;
  white-space: nowrap;
`;

Profile.propTypes = profilePropTypes;
Profile.defaultProps = profileDefaultProps;

const ObservedProfile = observer(Profile);
export { ObservedProfile as Profile };
