import { endsWith, map, pickBy, size } from 'lodash';
import PropTypes from 'prop-types';

function jsonToArrayWithKeys(json) {
  const object = JSON.parse(json);
  const propsWithValue = pickBy(object || {}, val => !!val && !Array.isArray(val) && size(val) > 0); // the SEOmatic API gives us back arrays instead of objects if there's not data for some reason.
  return map(propsWithValue, (data, key) => ({ key, data }));
}

const extractSeoMetaFromEntry = (entry, { searchUri, replaceUri, searchVariable, replaceVariable } = {}) => {
  const { metaTitleContainer = '{}', metaTagContainer = '{}', metaLinkContainer = '{}', metaJsonLdContainer = '{}' } =
    (entry && entry.seomatic) || {};

  const replaceUriAndVariables = containerJSON =>
    jsonToArrayWithKeys(containerJSON.replaceAll(searchUri, replaceUri).replaceAll(searchVariable, replaceVariable));

  const additionalLinkTags = replaceUriAndVariables(metaLinkContainer);
  const additionalMetaTags = replaceUriAndVariables(metaTagContainer);
  const jsonLinkedData = replaceUriAndVariables(metaJsonLdContainer);
  const metaTitleOverride = replaceUriAndVariables(metaTitleContainer);

  // hreflang needs to be hrefLang to make it a React prop.
  additionalLinkTags.forEach(additionalLinkTag => {
    const { data } = additionalLinkTag;

    // remove the /home for the canonical link
    if (data.rel === 'canonical' && endsWith(data.href, '/home')) {
      data.href = data.href.replace(/\/home$/i, '');
    }

    if (data.hreflang !== undefined) {
      data.hrefLang = data.hreflang;
      delete data.hreflang;
    }
  });

  return { additionalLinkTags, additionalMetaTags, jsonLinkedData, metaTitleOverride };
};

const additionalLinkTagsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    data: PropTypes.shape({
      rel: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  })
);

const additionalMetaTagsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    data: PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string,
    }),
  })
);

const jsonLinkedDataPropType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    data: PropTypes.shape({}),
  })
);

const seoPropTypes = {
  additionalLinkTags: additionalLinkTagsPropType,
  additionalMetaTags: additionalMetaTagsPropType,
  jsonLinkedData: jsonLinkedDataPropType,
};

const seoPropDefaults = {
  additionalLinkTags: [],
  additionalMetaTags: [],
  jsonLinkedData: [],
};

export { extractSeoMetaFromEntry, seoPropDefaults, seoPropTypes };
