import { compact } from 'lodash';
import { MARKET } from '@nandosaus/constants';
import { useCompetition, useRootStore } from '@nandosaus/state-management';

import { navigation } from '../../constants';
import { useMarket } from '../use-market';

const useAccountNavigationItems = () => {
  const market = useMarket();
  const { FeatureFlagStore, GroupOrderStore } = useRootStore();

  const isAustralianMarket = market === MARKET.AUSTRALIA;
  const { entries, isActive: hasActiveCompetition, userCanEnter: hasCompetitionEntries } = useCompetition();

  return compact([
    {
      label: 'Profile',
      href: navigation.ACCOUNT,
    },
    !GroupOrderStore.isGroupInvitee
      ? {
          label: 'Your Offers',
          href: navigation.OFFERS,
        }
      : null,
    {
      label: 'Orders',
      href: navigation.ORDERS,
    },
    {
      label: 'Points Activity',
      href: navigation.POINTS_ACTIVITY,
    },
    isAustralianMarket
      ? {
          label: 'Payment Methods',
          href: navigation.PAYMENT_METHODS,
        }
      : null,
    {
      label: 'Add Receipt',
      href: navigation.ADD_RECEIPT,
    },
    hasActiveCompetition
      ? {
          label: 'Spin-to-Win',
          href: navigation.SPIN_TO_WIN,
          badge: hasCompetitionEntries ? entries : undefined,
        }
      : null,
    isAustralianMarket
      ? {
          label: 'PERi-Perks Shop',
          href: navigation.PERKS_SHOP,
        }
      : null,
    FeatureFlagStore.isActive('digital-wallet')
      ? {
          label: 'Digital Wallet',
          href: navigation.DIGITAL_WALLET,
        }
      : null,
    FeatureFlagStore.isActive('communication-preferences')
      ? {
          label: 'Communication Preferences',
          href: navigation.COMMUNICATION_PREFERENCES,
        }
      : null,
    {
      label: 'Help',
      href: navigation.HELP,
    },
  ]);
};

export { useAccountNavigationItems };
