import PropTypes from 'prop-types';

const restaurantPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  address: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    country: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    suburb: PropTypes.string.isRequired,
  }),
});

const restaurantsArrayPropTypes = PropTypes.arrayOf(restaurantPropTypes);

export { restaurantPropTypes, restaurantsArrayPropTypes };
