import { useMediaQuery } from 'react-responsive';
import { useIsHydrated } from '@hooks/use-is-hydrated';
import { breakpoints } from '../../constants';

const useIsMinWidth = breakpoint => {
  const isHydrated = useIsHydrated();

  const IsMinWidth = useMediaQuery(
    {
      query: `(min-width: ${breakpoints[breakpoint]})`,
    },
    isHydrated ? undefined : { deviceWidth: 1600 }
  );
  return IsMinWidth;
};

export { useIsMinWidth };
