import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../../analytics';
import { breakpoints, navigation } from '../../../constants';
import { Button } from '../styles';
import { Drawer } from '../../drawer';
import { Flex } from '../../grid';
import { Icon } from '../../icons';
import { Label, Subheading } from '../../typography';
import { NavLink } from '../../nav-link';
import { useIsMinWidth } from '../../../hooks/use-is-min-width';
import { useNavigationItems } from '../../../hooks/use-navigation-items';

const Nav = styled(Flex)`
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    width: auto;
  }
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  height: 1px;
  margin: 2rem 0;
  width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    height: 85%;
    margin: 0 4rem 0 0;
    width: 1px;
  }
`;

const ContentWrapper = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 2rem 1rem;
  width: 100%;
`;

const MobileNavigation = ({ isOpen, onClose }) => {
  const primaryNavigationItems = useNavigationItems('primary');
  const secondaryNavigationItems = useNavigationItems('secondary');
  // filter out duplicate items that appear in both the primary and secondary nav
  const filteredSecondaryNavigationItems = secondaryNavigationItems.filter(item => {
    return !primaryNavigationItems.find(({ href }) => item.href === href);
  });
  const anchor = useIsMinWidth('sm') ? 'top' : 'left';

  return (
    <Drawer open={isOpen} onClose={onClose} anchor={anchor}>
      <Flex flexWrap="wrap">
        <Button p="1.5rem" onClick={onClose}>
          <Icon height="24px" width="24px" name="cross" />
        </Button>
        <ContentWrapper mb="2rem">
          <Nav mr={{ xs: 0, sm: '10rem' }}>
            <NavLink
              href={navigation.MENU}
              pb="1rem"
              pt=".75rem"
              onClick={() =>
                analytics.track(ANALYTICS_EVENTS.HEADER_NAV_ITEM_CLICKED, {
                  text: 'Order Food',
                })
              }
            >
              <Subheading as="span" display="flex">
                Order Food
              </Subheading>
            </NavLink>
            {primaryNavigationItems.slice(1).map(({ label, href }) => (
              <NavLink
                onClick={() =>
                  analytics.track(ANALYTICS_EVENTS.HEADER_NAV_ITEM_CLICKED, {
                    text: label,
                  })
                }
                href={href}
                key={href}
                py="1rem"
              >
                <Subheading as="span" display="flex">
                  {label}
                </Subheading>
              </NavLink>
            ))}
          </Nav>
          <Divider />
          <Nav>
            {filteredSecondaryNavigationItems.map(({ label, href, target }) => (
              <NavLink
                key={href}
                onClick={() =>
                  analytics.track(ANALYTICS_EVENTS.HEADER_NAV_ITEM_CLICKED, {
                    text: label,
                  })
                }
                href={href}
                target={target}
                py=".75rem"
              >
                <Label as="span" display="flex">
                  {label}
                </Label>
              </NavLink>
            ))}
          </Nav>
        </ContentWrapper>
      </Flex>
    </Drawer>
  );
};

MobileNavigation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { MobileNavigation };
