import { has } from 'lodash';
import { MARKET } from '@nandosaus/constants';
import { useEffect } from 'react';
import { useRootStore } from '@nandosaus/state-management';

import { getNavigationItems } from '../../content/get-navigation-items';
import { useMarket } from '../use-market';

const useNavigationItems = navType => {
  const market = useMarket();

  const { MenuStore } = useRootStore();
  const { allergenPDF, fetchAllergenPDF } = MenuStore;

  useEffect(() => {
    fetchAllergenPDF();
  }, [fetchAllergenPDF]);

  const allergenNavigationItem = market !== MARKET.AUSTRALIA &&
    has(allergenPDF, 'url') && {
      label: 'Allergen Information',
      href: allergenPDF?.url,
      target: '_blank',
    };

  return getNavigationItems(navType, { market, allergenNavigationItem });
};

export { useNavigationItems };
