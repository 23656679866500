import { Modal } from '@material-ui/core';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../grid';
import { breakpoints } from '../../constants';
import { Button } from '../button';
import { Icon } from '../icons';
import { LoadingIndicator } from '../icons/loading-indicator';
import { P, Subheading } from '../typography';

const AlertModal = ({ open, buttonText, isLoading, title, handleClose, children, footer }) => {
  const isDismissible = isFunction(handleClose);

  return (
    <StyledMaterialModal disableAutoFocus disableEnforceFocus open={open}>
      <Wrapper
        flexDirection="column"
        backgroundColor="white"
        py={isDismissible ? { xs: 3, md: 2 } : { xs: 4, md: 3 }}
        px={{ xs: 2, md: 1 }}
      >
        {isDismissible && (
          <CloseIconButton type="button" onClick={handleClose}>
            <Icon name="cross" width="24px" height="24px" fill="greyPrimary" />
          </CloseIconButton>
        )}
        {title && (
          <Subheading textAlign="center" variant={2} mb={1} mt={isDismissible && '-1rem'}>
            {title}
          </Subheading>
        )}
        {isLoading && (
          <Box mb={1}>
            <LoadingIndicator />
          </Box>
        )}
        <Content textAlign="center" variant={2}>
          {children}
        </Content>
        {isDismissible && buttonText && (
          <Flex mt="1.5rem" justifyContent="center">
            <Button onClick={handleClose}>{buttonText}</Button>
          </Flex>
        )}
        {footer && <Box mt={2}>{footer}</Box>}
      </Wrapper>
    </StyledMaterialModal>
  );
};

const StyledMaterialModal = styled(Modal)`
  align-items: center;
  display: flex;
  justify-content: center;

  @keyframes easeInAnim {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: easeInAnim 0.2s ease-in;
`;

const Wrapper = styled(Flex)`
  width: 100%;
  margin: 1rem;

  @media (min-width: ${breakpoints.md}) {
    height: ${({ webHeight }) => webHeight};
    max-width: ${breakpoints.sm};
    max-height: 85%;
  }
`;

const CloseIconButton = styled.button`
  top: -1.5rem;
  left: 0.5rem;
  position: relative;
  display: flex;
  justify-content: flex-end;

  @media (min-width: ${breakpoints.md}) {
    top: -1rem;
    left: 0;
  }
`;

const Content = styled(P)`
  white-space: pre-wrap;
`;

AlertModal.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  handleClose: PropTypes.func,
  isLoading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

AlertModal.defaultProps = {
  buttonText: null,
  footer: undefined,
  handleClose: null,
  isLoading: false,
  title: null,
};

export { AlertModal };
