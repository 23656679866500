import PropTypes from 'prop-types';

const perksShopImage = PropTypes.shape({
  alt: PropTypes.string,
  url: PropTypes.string,
  srcset: PropTypes.string,
  hi: PropTypes.string,
  lo: PropTypes.string,
  focalPoint: PropTypes.arrayOf(PropTypes.number),
});

const perksShopProductPropTypes = PropTypes.shape({
  id: PropTypes.string,
  description: PropTypes.string,
  heading: PropTypes.string,
  category: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.arrayOf(perksShopImage), perksShopImage]),
  name: PropTypes.string,
  pointsPrice: PropTypes.number,
  stock: PropTypes.number,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      plu: PropTypes.number,
      stock: PropTypes.number,
      color: PropTypes.string,
      size: PropTypes.string,
    })
  ),
  quantity: PropTypes.number,
});

const perksShopProductArrayPropTypes = PropTypes.arrayOf(perksShopProductPropTypes);

export { perksShopProductPropTypes, perksShopProductArrayPropTypes };
