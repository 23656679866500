import PropTypes from 'prop-types';

const breadcrumbPropTypes = PropTypes.shape({
  title: PropTypes.oneOfType[(PropTypes.string, PropTypes.node)],
  ancestors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
});

export { breadcrumbPropTypes };
