import styled, { css } from 'styled-components';

import { breakpoints } from '../../constants';

/**
 * Custom IOS Button Wrapper to add bottom padding and overlay for button
 * making them appear above the ios tray bar and not hide them.
 * Adds an overlay at the bottom of the button and adds ios tray padding
 * Animates the layer to go along with other animations
 */
const IOSButtonWrapper = styled.div`
  @keyframes background-animation {
    from {
      background-color: rgba(255, 255, 255, 0.1);
    }
    to {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  width: 100%;
  ${({ fixedBreakpoint }) => css`
    @media (max-width: ${breakpoints[fixedBreakpoint]}) {
      position: fixed;
      bottom: 0;
      z-index: ${({ theme }) => theme.zIndexes[5]};
      padding-bottom: env(safe-area-inset-bottom);
      background-color: rgba(255, 255, 255, 0.9);
      animation: background-animation 1s ease-in;
    }
  `};
`;

export { IOSButtonWrapper };
