import PropTypes from 'prop-types';
import React from 'react';

import { AccountNav } from '../../account-nav';
import { Box, Flex } from '../../grid';
import { Button } from '../styles';
import { Drawer } from '../../drawer';
import { Icon } from '../../icons';
import { PerksBalance } from '../../perks-balance';
import { profileDefaultProps, profilePropTypes } from '../../../prop-types';
import { Subheading } from '../../typography';
import { useAccountNavigationItems } from '../../../hooks/use-account-navigation-items';
import { useIsMinWidth } from '../../../hooks/use-is-min-width';

const NAV_ITEMS_PER_COL = 5;

const AccountNavigationDrawer = ({ profile, handleSignOut, isOpen, onClose }) => {
  const anchor = useIsMinWidth('md') ? 'top' : 'right';
  const accountNavigationItems = useAccountNavigationItems();

  return (
    <Drawer open={isOpen} onClose={onClose} anchor={anchor}>
      <Flex flexWrap="wrap" pb="5rem">
        <Flex width="100%" justifyContent="flex-end">
          <Button p="1.5rem" onClick={onClose}>
            <Icon height="24px" width="24px" name="cross" />
          </Button>
        </Flex>
        <Flex mt="-2rem" p={{ _: 0, md: '1rem', lg: 0 }} width="100%" flexWrap="wrap" justifyContent="center">
          <Flex flexDirection="column" pb="1.5rem" pl="1.5rem" pr={2} width={{ xs: 1, md: 4 / 12, lg: 1 }}>
            <Subheading variant={2} mb={2}>
              Hi {profile.givenName}!
            </Subheading>
            <PerksBalance />
          </Flex>
          <Box bg="background" height={4} width="100%" mb={1} display={{ _: 'block', md: 'none' }} />
          <AccountNav
            pr="1.5rem"
            width={{ xs: 1, md: 4 / 12, lg: 1 }}
            routes={accountNavigationItems.slice(0, NAV_ITEMS_PER_COL)}
          />
          <AccountNav
            pr={2}
            width={{ xs: 1, md: 4 / 12, lg: 1 }}
            handleSignOut={handleSignOut}
            routes={accountNavigationItems.slice(NAV_ITEMS_PER_COL)}
          />
        </Flex>
      </Flex>
    </Drawer>
  );
};

AccountNavigationDrawer.propTypes = {
  ...profilePropTypes,
  handleSignOut: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AccountNavigationDrawer.defaultProps = profileDefaultProps;

export { AccountNavigationDrawer };
