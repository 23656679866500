import PropTypes from 'prop-types';

const cmsImagePropTypes = PropTypes.shape({
  alt: PropTypes.string,
  focalPoint: PropTypes.arrayOf(PropTypes.number),
  srcset: PropTypes.string,
  url: PropTypes.string.isRequired,
});

const cmsImagesPropTypes = PropTypes.arrayOf(cmsImagePropTypes);

export { cmsImagePropTypes, cmsImagesPropTypes };
