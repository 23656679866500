import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '../grid';
import { fontSize } from '../../constants';
import { icons } from './icons';
import { NavIcon, NavLink } from './styles';
import { P } from '../typography';

const NavItem = ({ active, label, href, badge, ...rest }) => (
  <NavLink href={href} key={href} active={active} {...rest}>
    <Box flexShrink={0}>
      <NavIcon name={icons[label].name} fill={icons[label].fill} width="24px" height="24px" />
    </Box>
    <P variant={3}>{label}</P>
    {!!badge && (
      <Box
        ml="auto"
        backgroundColor="periRed"
        borderRadius="50%"
        width="26px"
        height="26px"
        fontSize={fontSize[9]}
        lineHeight="26px"
        textAlign="center"
        color="white"
      >
        {badge}
      </Box>
    )}
  </NavLink>
);

NavItem.propTypes = {
  active: PropTypes.bool,
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  badge: PropTypes.number,
};

NavItem.defaultProps = {
  active: false,
  badge: undefined,
};

export { NavItem };
