import PropTypes from 'prop-types';

const offerPropTypes = PropTypes.shape({
  barcode: PropTypes.string,
  description: PropTypes.string,
  discountAmount: PropTypes.number,
  discountLimit: PropTypes.number,
  discountType: PropTypes.string,
  discountUsage: PropTypes.number,
  expiryDate: PropTypes.string,
  hash: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.string,
  startDate: PropTypes.string,
  longDescription: PropTypes.string,
  name: PropTypes.string,
  minOrderValue: PropTypes.number,
  minimumOrderSpend: PropTypes.number,
  plus: PropTypes.arrayOf(PropTypes.string),
  restaurantIds: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.number,
  isPromoCode: PropTypes.bool,
});

export { offerPropTypes };
