import styled from 'styled-components';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';
import { breakpoints } from '../../constants';
import { Col, Flex } from '../grid';
import { Icon } from '../icons';
import { Link } from '../link';
import { NavLink } from '../nav-link';
import { P } from '../typography';
import { useNavigationItems } from '../../hooks/use-navigation-items';

const CURRENT_YEAR = new Date().getFullYear();

// styles must sit above component to prevent babel-loader error
const Wrapper = styled(Flex)`
  flex-direction: column;

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;

const Copyright = styled(P)`
  font-size: 9px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 11px;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 12px;
  }
`;

const LinkText = styled(Copyright)`
  color: ${({ theme }) => theme.colors.greyPrimary};
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

const SocialMediaLink = styled(Link)`
  :not(:last-child) {
    margin-right: 1.5rem;
  }
`;

const SocialMediaIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.grey300};

  :hover {
    fill: ${({ theme }) => theme.colors.greyPrimary};
  }
`;

const SecondaryNavigation = () => {
  const footerNavigationItems = useNavigationItems('footer');
  const socialNavigationItems = useNavigationItems('social');

  return (
    <Wrapper alignItems="center" flexWrap="wrap" mb={1}>
      <Col mr={1} mb={{ xs: '1.5rem', md: 1 }} alignItems="center">
        {socialNavigationItems.map(({ label, href, icon }) => (
          <SocialMediaLink href={href} key={href} target="_blank" title={label}>
            <SocialMediaIcon name={icon} />
          </SocialMediaLink>
        ))}
      </Col>
      <Col mr={2} mb={1}>
        <Flex flexWrap="wrap">
          {footerNavigationItems.map(({ label, href }) => (
            <NavLink
              onClick={() => {
                analytics.track(ANALYTICS_EVENTS.FOOTER_NAV_ITEM_CLICKED, {
                  text: label,
                });
              }}
              href={href}
              ml={1}
              key={href}
            >
              <LinkText>{label}</LinkText>
            </NavLink>
          ))}
        </Flex>
      </Col>
      <Col mb={1}>
        <Copyright color="grey300">© Copyright Nando&apos;s {CURRENT_YEAR}</Copyright>
      </Col>
    </Wrapper>
  );
};

export { SecondaryNavigation };
