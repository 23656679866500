import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';
import { Box, Flex } from '../grid';
import { breakpoints, navigation } from '../../constants';
import { Button } from '../button';
import { DesktopNavigation } from './navigation';
import { HeaderContainer } from './styles';
import { Link } from '../link';
import { Logo } from '../logo';
import { Profile } from './profile';
import { profileDefaultProps, profilePropTypes } from '../../prop-types';

// styles must sit above component to prevent babel-loader error
const LogoLink = styled(Link)`
  flex-shrink: 0;
  width: 120px;

  @media (min-width: ${breakpoints.xl}) {
    width: auto;
  }
`;

const SmallButton = styled(Button)`
  padding: 1rem 1.25rem;

  @media (min-width: ${breakpoints.xl}) {
    padding: 0.75rem 1rem;
  }
`;

const DesktopHeader = ({ display, profile, handleSignOut }) => {
  return (
    <HeaderContainer flex={1} display={display} data-testid="desktop-header">
      <Flex alignItems="center" flex={1}>
        <LogoLink href="/">
          <Logo height={52} />
        </LogoLink>
        <Box ml="1rem" width="100%" height="100%">
          <DesktopNavigation />
        </Box>
      </Flex>
      <Flex alignItems="center">
        <Box mr={1}>
          <SmallButton
            onClick={() =>
              analytics.track(ANALYTICS_EVENTS.HEADER_NAV_ITEM_CLICKED, {
                text: 'Order Food',
              })
            }
            href={navigation.MENU}
          >
            Order Food
          </SmallButton>
        </Box>
        <Profile profile={profile} handleSignOut={handleSignOut} />
      </Flex>
    </HeaderContainer>
  );
};

DesktopHeader.propTypes = profilePropTypes;
DesktopHeader.defaultProps = profileDefaultProps;

const ComposedDesktopHeader = observer(DesktopHeader);

export { ComposedDesktopHeader as DesktopHeader };
