import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import React from 'react';
import StyledPropTypes from '@styled-system/prop-types';

import { Flex } from '../grid';
import { Icon } from '../icons';
import { P, Subheading } from '../typography';

const PerksBalance = ({ alignItems }) => {
  const { MemberStore } = useRootStore();
  const { profile } = MemberStore;
  const {
    balances: { points },
  } = profile;

  const dollarValue = profile.pointsInDollars;

  return (
    <Flex flexDirection="column" {...{ alignItems }}>
      <Flex mb="0.5rem" alignItems="center">
        <Icon
          name="periPerksLogo"
          height={{ _: '24px', md: '28px', lg: '30px' }}
          width={{ _: '24px', md: '28px', lg: '30px' }}
        />
        <Subheading px="0.5rem" variant={2}>
          {points.toLocaleString()} {points === '1' ? 'point' : 'points'}
        </Subheading>
      </Flex>
      <P variant={3}>Redeemable for ${dollarValue} Nando’s dollars</P>
    </Flex>
  );
};

PerksBalance.propTypes = {
  alignItems: StyledPropTypes.flexbox.alignItems,
};

PerksBalance.defaultProps = {
  alignItems: 'flex-start',
};

const ObservedPerksBalance = observer(PerksBalance);
export { ObservedPerksBalance as PerksBalance };
