import { space } from 'styled-system';
import styled from 'styled-components';

import { breakpoints } from '../../constants';
import { Container } from '../grid';

const HeaderContainer = styled(Container)`
  background-color: white;
  justify-content: space-between;

  @media (min-width: ${breakpoints.lg}) {
    min-height: 75px;
  }
`;

const Button = styled.button`
  ${space}
  white-space: nowrap;
`;

export { Button, HeaderContainer };
