import { compact } from 'lodash';
import { MARKET } from '@nandosaus/constants';

import { navigation } from '../constants';

const isAustralia = (market, AuNavItem, navItem) => (market === MARKET.AUSTRALIA ? AuNavItem : navItem);

const getPrimaryNavigationItems = market =>
  compact([
    {
      label: 'Menu',
      href: navigation.MENU,
    },
    {
      label: 'Restaurants',
      href: navigation.RESTAURANTS,
    },
    {
      label: 'PERi Products',
      href: navigation.PRODUCTS,
      cmsLink: true,
    },
    isAustralia(
      market,
      {
        label: 'Our Purpose',
        href: navigation.CHANGING_LIVES,
        cmsLink: true,
      },
      {
        label: 'Our Story',
        href: navigation.OUR_STORY,
        cmsLink: true,
      }
    ),
    {
      label: 'PERi-Perks',
      href: navigation.PERI_PERKS,
      preserveLabel: 'PERi-PERKS',
    },
    {
      label: 'Careers',
      href: navigation.CAREERS,
      cmsLink: true,
    },
  ]);

const getSecondaryNavigationItems = ({ market, allergenNavigationItem }) =>
  compact([
    {
      label: 'Download App',
      href: navigation.APP,
    },
    isAustralia(market, {
      label: 'Nutritional Information',
      href: navigation.NUTRITIONAL_INFORMATION,
    }),
    isAustralia(market, {
      label: 'Perks Shop',
      href: navigation.PERKS_SHOP,
    }),
    isAustralia(market, {
      label: 'Catering',
      href: 'https://catering.nandos.com.au/site',
      target: '_blank',
    }),
    {
      label: 'Recipes',
      href: navigation.RECIPES,
    },
    { label: 'News', href: navigation.NEWS },
    {
      label: 'Gift Cards',
      href: navigation.GIFT_CARDS,
    },
    allergenNavigationItem,
    {
      label: 'Careers',
      href: navigation.CAREERS,
    },
    {
      label: 'Help',
      href: navigation.HELP,
      target: null,
    },
  ]);

const getSocialNavigationItems = market => {
  if (market === MARKET.AUSTRALIA) {
    return [
      {
        label: 'Nando’s on Facebook',
        href: 'https://www.facebook.com/nandosaus',
        icon: 'facebook',
      },
      {
        label: 'Nando’s on Instagram',
        href: 'https://www.instagram.com/nandosaus',
        icon: 'instagram',
      },
      {
        label: 'Nando’s on Twitter',
        href: 'https://twitter.com/nandosaus',
        icon: 'twitter',
      },
    ];
  }

  if (market === MARKET.NEW_ZEALAND) {
    return [
      {
        label: 'Nando’s on Facebook',
        href: 'https://www.facebook.com/NZNandos',
        icon: 'facebook',
      },
      {
        label: 'Nando’s on Instagram',
        href: 'https://www.instagram.com/nznandos',
        icon: 'instagram',
      },
    ];
  }

  throw new Error(`Unsupported market specified: ${market}`);
};

const getNavigationItems = (navType, { market, allergenNavigationItem }) => {
  switch (navType) {
    case 'primary':
      return getPrimaryNavigationItems(market);
    case 'secondary':
      return getSecondaryNavigationItems({ market, allergenNavigationItem });
    case 'social':
      return getSocialNavigationItems(market);
    case 'footer':
      return [
        {
          label: 'Terms & Conditions',
          href: navigation.TERMS_CONDITIONS,
        },
        {
          label: 'Privacy Policy',
          href: navigation.PRIVACY_POLICY,
        },
      ];
    default:
      throw new Error(`Unsupported navType specified: ${navType}`);
  }
};

export { getNavigationItems };
