import PropTypes from 'prop-types';

const productPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
});

const productsArrayPropTypes = PropTypes.arrayOf(productPropTypes);

export { productPropTypes, productsArrayPropTypes };
