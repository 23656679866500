import { observer } from 'mobx-react';
import { space } from 'styled-system';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';
import { breakpoints, navigation } from '../../constants';
import { Button, HeaderContainer } from './styles';
import { Flex } from '../grid';
import { Icon } from '../icons';
import { Label } from '../typography';
import { Link } from '../link';
import { Logo } from '../logo';
import { MobileNavigation } from './navigation';
import { Profile } from './profile';
import { profileDefaultProps, profilePropTypes } from '../../prop-types';

const NavLink = styled(Link)`
  ${space}
  color: ${({ theme }) => theme.colors.black};
  display: none;

  @media (min-width: ${breakpoints.sm}) {
    display: block;
  }
`;

const NavLabel = styled(Label)`
  display: flex;
  white-space: nowrap;
`;

const MobileHeader = ({ display, profile, handleSignOut }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HeaderContainer display={display}>
      <Flex width={4 / 12}>
        <Button
          type="button"
          title="menu"
          p={1}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <Icon name="hamburger" width="20px" height="20px" />
        </Button>
        <MobileNavigation
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </Flex>
      <Flex justifyContent="center" alignItems="center" width={4 / 12}>
        <Link href="/">
          <Logo width={100} height={32} />
        </Link>
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end" width={4 / 12}>
        <NavLink
          href={navigation.MENU}
          mr="1rem"
          onClick={() =>
            analytics.track(ANALYTICS_EVENTS.HEADER_NAV_ITEM_CLICKED, {
              text: 'Menu',
            })
          }
        >
          <NavLabel>Order Food</NavLabel>
        </NavLink>
        <Profile profile={profile} handleSignOut={handleSignOut} />
      </Flex>
    </HeaderContainer>
  );
};

MobileHeader.propTypes = profilePropTypes;
MobileHeader.defaultProps = profileDefaultProps;

const ComposedMobileHeader = observer(MobileHeader);

export { ComposedMobileHeader as MobileHeader };
