import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import { useRouter } from 'next/router';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { analytics } from '../../analytics';
import { Box, Flex } from '../grid';
import { ErrorBoundary } from '../error-boundary';
import { floodlightPageLoadEventPropTypes } from '../../prop-types';
import { Footer } from '../footer';
import { Header } from '../header';
import { PageAlert } from '../page-alert';
import { PageProvider } from '../page-context';
import { ScreenButton } from '../screen-button';
import { seoPropDefaults, seoPropTypes } from '../../utils/extract-seo-meta-from-entry';
import { useFeature } from '../../hooks/use-feature';

const defaultRouter = { pathname: null };

const Layout = ({
  title,
  metaContent,
  onScreenButtonProps,
  children,
  floodlightPageLoadEvent,
  hasBackgroundPattern,
}) => {
  const { MemberStore, AuthStore } = useRootStore();
  const { profile } = MemberStore;

  const router = useRouter() || defaultRouter;

  useEffect(() => {
    // @NOTE: lock the user to sign in page
    if (router.pathname !== '/sign-in' && profile && !profile.mobile) {
      router.replace('/sign-in');
    }
  }, [profile, router, router.pathname]);

  useEffect(() => {
    if (floodlightPageLoadEvent) {
      analytics.plugins['google-analytics'].trackFloodlightEvent(floodlightPageLoadEvent);
    }
  }, [floodlightPageLoadEvent]);

  const { additionalLinkTags, additionalMetaTags, jsonLinkedData, metaTitleOverride } = metaContent;
  const pageTitle = get(metaTitleOverride, '[0].data.title', title);

  const maintenanceMode = useFeature('maintenance');

  return (
    <Wrapper>
      <PageProvider title={title}>
        <Head>
          <title>{pageTitle}</title>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="theme-color" content="#000000" />
          <link
            rel="preload"
            href="/static/fonts/nandos-hand-alt.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/static/fonts/Barlow-Regular.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/static/fonts/Barlow-Italic.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <meta name="viewport" content="initial-scale=1.0, width=device-width, viewport-fit=cover" key="viewport" />
          <meta property="og:title" content={title} />

          {!isEmpty(additionalMetaTags) && additionalMetaTags.map(tag => <meta key={tag.key} {...tag.data} />)}
          {!isEmpty(additionalLinkTags) && additionalLinkTags.map(tag => <link key={tag.key} {...tag.data} />)}
          {!isEmpty(jsonLinkedData) &&
            jsonLinkedData.map(data => (
              <script
                key={data.key}
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(data.data) }} // eslint-disable-line react/no-danger
              />
            ))}

          <link rel="shortcut icon" type="image/x-icon" href="/static/favicon.ico" />
        </Head>
        {!maintenanceMode ? (
          <>
            <Header profile={profile} handleSignOut={AuthStore.signOut} />
            <Main hasBackgroundPattern={hasBackgroundPattern}>
              <ErrorBoundary asPage>{children}</ErrorBoundary>
            </Main>
            <Footer />
            <ScreenButton {...onScreenButtonProps} />
            <PageAlert />
          </>
        ) : (
          <Flex justifyContent="center" alignItems="center" height="100vh">
            <Box>
              Unfortunately we’re offline at the moment performing critical maintenance. We&apos;ll back back ASAP!
            </Box>
          </Flex>
        )}
      </PageProvider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex-grow: 1;
  ${({ hasBackgroundPattern }) => (hasBackgroundPattern ? `background-image: url(/static/images/background.svg);` : ``)}
  background-size: cover;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  floodlightPageLoadEvent: floodlightPageLoadEventPropTypes,
  metaContent: PropTypes.shape(seoPropTypes),
  onScreenButtonProps: PropTypes.shape({
    itemCount: PropTypes.number,
    onClick: PropTypes.func,
    price: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  hasBackgroundPattern: PropTypes.bool,
};

Layout.defaultProps = {
  floodlightPageLoadEvent: undefined,
  metaContent: seoPropDefaults,
  onScreenButtonProps: null,
  hasBackgroundPattern: false,
};

const ObservedLayout = observer(Layout);
export { ObservedLayout as Layout };
