import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { Button } from '../button';
import { Flex } from '../grid';
import { IOSButtonWrapper } from '../ios-button-wrapper';
import { Label } from '../typography';
import { useIsMinWidth } from '../../hooks/use-is-min-width';

const ScreenButton = ({ onClick, itemCount, price }) => {
  const isWeb = useIsMinWidth('lg');

  const showScreenCheckoutButton = !isWeb && itemCount > 0 && !!onClick;

  if (!showScreenCheckoutButton) {
    return null;
  }

  return (
    <IOSButtonWrapper fixedBreakpoint="lg">
      <Button
        as={motion.button}
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition="0.2s ease-in-out 0.3s"
        variant="action"
        leftContent={
          <Flex
            justifyContent="center"
            alignItems="center"
            height={20}
            width={20}
            border={1}
            borderStyle="solid"
            overflow="hidden"
          >
            <AnimatePresence>
              <Label
                position="absolute"
                as={motion.p}
                key={itemCount}
                initial={itemCount === 1 ? {} : { y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ ease: 'easeOut', duration: 0.5, delay: 0.3 }}
                exit={{ y: -10, opacity: 0 }}
              >
                {itemCount}
              </Label>
            </AnimatePresence>
          </Flex>
        }
        rightText={price}
        onClick={onClick}
      >
        View Order
      </Button>
    </IOSButtonWrapper>
  );
};

ScreenButton.propTypes = {
  itemCount: PropTypes.number,
  onClick: PropTypes.func,
  price: PropTypes.string,
};

ScreenButton.defaultProps = {
  itemCount: 0,
  onClick: null,
  price: null,
};

export { ScreenButton };
