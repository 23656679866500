import { Cache } from 'aws-amplify';

import { navigation } from '../constants';

const LANDING_PAGE_KEY = 'landingPage';

const getLandingPage = () => {
  try {
    return Cache.getItem(LANDING_PAGE_KEY) || navigation.HOME;
  } catch (error) {
    return navigation.HOME;
  }
};

const setLandingPage = landingPage => {
  try {
    Cache.setItem(LANDING_PAGE_KEY, landingPage);
  } catch (error) {
    // ignore
  }
};

const removeLandingPage = () => {
  try {
    Cache.removeItem(LANDING_PAGE_KEY);
    // ignore
  } catch (error) {
    // ignore
  }
};

export { getLandingPage, setLandingPage, removeLandingPage };
