import PropTypes from 'prop-types';
import React from 'react';

import { AccountNavigationDrawer } from './account-nav-drawer';
import { AccountNavigationMenu } from './account-nav-menu';
import { profileDefaultProps, profilePropTypes } from '../../../prop-types';
import { useIsMinWidth } from '../../../hooks/use-is-min-width';

const AccountNavigation = ({ profile, handleSignOut, isOpen, onClose }) => {
  const isLg = useIsMinWidth('lg');

  if (isLg) {
    return <AccountNavigationMenu handleSignOut={handleSignOut} isOpen={isOpen} onClose={onClose} />;
  }

  return <AccountNavigationDrawer handleSignOut={handleSignOut} profile={profile} isOpen={isOpen} onClose={onClose} />;
};

AccountNavigation.propTypes = {
  ...profilePropTypes,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AccountNavigation.defaultProps = profileDefaultProps;

export { AccountNavigation };
