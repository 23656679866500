import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { AccountNav } from '../../account-nav';
import { breakpoints } from '../../../constants';
import { ContentWrapper } from './styles';

const AccountNavigationMenu = ({ handleSignOut, isOpen, onClose }) => {
  const menuRef = useRef(null);

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Menu ref={menuRef} isOpen={isOpen} onClose={onClose}>
      <ContentWrapper>
        <AccountNav handleSignOut={handleSignOut} />
      </ContentWrapper>
    </Menu>
  );
};

const Menu = styled.div`
  align-items: flex-start;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0px 10px 30px 10px rgba(43, 43, 43, 0.3);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 2.75rem;
  z-index: 30;

  @media (min-width: ${breakpoints.xl}) {
    top: 2.25rem;
  }
`;

AccountNavigationMenu.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ObservedAccountNavigationMenu = observer(AccountNavigationMenu);
export { ObservedAccountNavigationMenu as AccountNavigationMenu };
