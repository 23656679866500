import PropTypes from 'prop-types';

import { cmsImagePropTypes, cmsImagesPropTypes } from './cms-image';
import { cmsVideosPropTypes } from './cms-video';

const recipePropType = PropTypes.shape({
  category: PropTypes.string,
  contentMatrix: PropTypes.string,
  cookTime: PropTypes.string,
  description: PropTypes.string,
  heading: PropTypes.string,
  image: cmsImagesPropTypes,
  instructions: cmsImagesPropTypes,
  pageHeaderImage: cmsImagePropTypes,
  prepTime: PropTypes.string,
  servingSize: PropTypes.string,
  title: PropTypes.string,
  video: cmsVideosPropTypes,
});

const recipesPropType = PropTypes.arrayOf(recipePropType);

const recipeCardPropType = PropTypes.shape({
  cookTime: PropTypes.string,
  heading: PropTypes.string,
  href: PropTypes.string,
  image: cmsImagePropTypes,
  prepTime: PropTypes.string,
});

export { recipesPropType, recipeCardPropType, recipePropType };
