const icons = {
  Profile: {
    name: 'user',
    fill: '#000000',
  },
  'Your Offers': {
    name: 'coupon',
    fill: '#000000',
  },
  Orders: {
    name: 'document',
    fill: '#000000',
  },
  'Add Receipt': {
    name: 'receipt',
    fill: '#000000',
  },
  'PERi-Perks Shop': {
    name: 'perksShop',
    fill: '#000000',
  },
  'Communication Preferences': {
    name: 'heartOutline',
    fill: '#000000',
  },
  'Payment Methods': {
    name: 'card',
    fill: '#000000',
  },
  'Points Activity': {
    name: 'statement',
    fill: '#000000',
  },
  Help: {
    name: 'lifebuoy',
    fill: '#000000',
  },
  'Spin-to-Win': {
    name: 'giftCard',
    fill: '#000000',
  },
  'Digital Wallet': {
    name: 'digitalWallet',
    fill: '#000000',
  },
};

export { icons };
