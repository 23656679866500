import moment from 'moment';

const isCompetitionActive = competition => {
  if (!competition) {
    return false;
  }
  if (!moment().isBetween(competition.startDate, competition.endDate)) {
    return false;
  }

  return true;
};

export { isCompetitionActive };
