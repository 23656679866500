import { space } from 'styled-system';
import styled, { css } from 'styled-components';

import { breakpoints } from '../../constants';
import { Flex } from '../grid';
import { Icon } from '../icons';
import { NavLink as SharedNavLink } from '../nav-link';

const Nav = styled(Flex)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
`;

const NavItemStyles = css`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  border-left: 2px solid ${({ active, theme }) => (active ? theme.colors.neonTeal400 : 'transparent')};
  display: flex;
  white-space: nowrap;

  &:hover {
    border-left: 2px solid ${({ theme }) => theme.colors.neonTeal400};
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-right: 0;
    padding-right: 1rem;
  }
`;

const NavButton = styled.button`
  ${NavItemStyles}
  ${space}
`;

const NavLink = styled(SharedNavLink)`
  ${NavItemStyles}
`;

const NavIcon = styled(Icon)`
  margin-right: 1rem;
`;

export { Nav, NavLink, NavButton, NavIcon };
